.sc-box{
    width: 100%;
    height: 1400px;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
}

.sc-hair{
    font-size: 40px;
    text-align: center;
    padding-top: 50px;
    margin: 25px;
}
.sc-serv{
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    width: 1250px;
    padding-top: 0;
    margin-top: 60px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
    overflow: hidden;
}
.sc-serv h1{
    font-size: 26px;
    margin: 0;
    padding: 0;
    font-family: "Montserrat";
}
.sc-serv hr{
    height: 0px;
    background-color: #000000;
    border: 1px solid black;
    width: 20svw;
}

@media (max-width:1350px) {
    .sc-box{
        width: 100%;
        height: 1300px;
    }
    .sc-serv{
        width: 924px;/* 1024-100 */
    }
    .sc-serv h1{
        font-size: 23px;
    }
    .sc-serv hr{
        width: 25svw;
    }
    .sc-hair{
        font-size: 36px;
    }

}
@media (max-width:1024px) {
    .sc-box{
        height: 1130px;

    }
    .sc-serv{
        width: 700px;/* 768-20 */
    }
    .sc-serv h1{
        font-size: 22px;
    }
    .sc-serv hr{
        width: 250px;
    }
    .sc-hair{
        font-size: 32px;
    }
}
@media (max-width:768px) {
    .sc-box{
        height: 1170px;

    }
    .sc-serv{
        width: 500px;/* 768-20 */
    }
    .sc-serv h1{
        font-size: 20px;
        width: auto;
    }
    .sc-serv hr{
        width: 200px;
    }
    .sc-hair{
        font-size: 29px;
    }
}
@media (max-width:565px) {
    
    .sc-box{
        height: 1130px;

    }
    .sc-serv{
        width: 400px;/* 768-20 */
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .sc-serv h1{
        font-size: 19px;
    }
    .sc-serv hr{
        width: 1000px;
    }
    .sc-hair{
        font-size: 29px;
    }
}
@media (max-width:440px) {
    .sc-serv h1{
        font-size: 18px;
    }
    .sc-serv{
        width: auto;/* 768-20 */
        margin-left: 20px;
        margin-right: 20px;
    }
    .sc-hair{
        font-size: 28px;
    }
}

@media (max-width:360px) {
    .sc-serv hr{
        width: 1000px;
        border: 0px;
    }
}