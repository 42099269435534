.ts-body{
    background-color: white;
    width: 100%;
    height: auto;
}

.ts-body h1{
    text-align: center;
    font-family: 'Montserrat';
    margin-top: 5svh;
    font-size: 40px;
}

.ts-body h2{
    text-align: center;
    font-family: 'Montserrat-Light';
    font-size: 25px;
    margin-right: 20px;
    margin-left: 20px;
}
@media (max-width:1500px) {
    .ts-body h1{
        margin-top: 5svh;
        font-size: 35px;
    }
    
    .ts-body h2{
        font-size: 22px;
        margin-bottom: 0;
    }
}
@media (max-width:1024px) {
    .ts-body h1{
        margin-top: 5svh;
        font-size: 30px;
    }
    
    .ts-body h2{
        font-size: 20px;
        margin-bottom: 0;
    }
}
@media (max-width:768px) {
    .ts-body h1{
        margin-top: 5svh;
        font-size: 28px;
    }
    
    .ts-body h2{
        font-size: 19px;
        margin-bottom: 0;
    }
}
@media (max-width:420px) {
    .ts-body h1{
        margin-top: 5svh;
        font-size: 27px;
    }
    
    .ts-body h2{
        font-size: 18px;
        margin-bottom: 0;
    }
}