.pr-box{
  width: 100%;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/prbgImg.png');
}
.pr-switch{
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  height: auto;
}
.pr-content {
  margin: 35px 5svw 5svw 5svw;
  min-width: 90svw;
  overflow: hidden;
  transition: height 0.3s ease;
}

.menu-1 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px; 
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-1.expanded {
  max-height:800px;
  transition: max-height 2.06s ease;
}
.menu-1.expanded .pr-menu-visible img{
  rotate:180deg;
}

.menu-2 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: all 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-2.expanded {
  max-height: 600px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: all 2.06s ease;
}
.menu-2.expanded .pr-menu-visible img{
  rotate:180deg;
}
.menu-3 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-3.expanded {
  max-height: 1300px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-3.expanded .pr-menu-visible img{
  rotate:180deg;
}

.menu-4 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-4.expanded {
  max-height: 1650px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-4.expanded .pr-menu-visible img{
  rotate:180deg;
}

.menu-5 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-5.expanded {
  max-height: 350px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-5.expanded .pr-menu-visible img{
  rotate:180deg;
}

.menu-6 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-6.expanded {
  max-height: 600px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-6.expanded .pr-menu-visible img{
  rotate:180deg;
}

.menu-7 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 1.06s ease;
  border-bottom: 1px solid black;
}
.menu-7.expanded {
  max-height: 1400px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 1.06s ease;
}
.menu-7.expanded .pr-menu-visible img{
  rotate:180deg;
}

.menu-8 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-8.expanded {
  max-height: 350px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 1.2s ease;
}
.menu-8.expanded .pr-menu-visible img{
  rotate:180deg;
}
.menu-9 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-9.expanded {
  max-height: 500px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-9.expanded .pr-menu-visible img{
  rotate:180deg;
}
.menu-10 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-10.expanded {
  max-height: 2000px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-10.expanded .pr-menu-visible img{
  rotate:180deg;
}
.menu-11 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-11.expanded {
  max-height: 750px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-11.expanded .pr-menu-visible img{
  rotate:180deg;
}
.menu-12 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-12.expanded {
  max-height: 850px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-12.expanded .pr-menu-visible img{
  rotate:180deg;
}
.menu-13 {
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 2.06s ease;
  border-bottom: 1px solid black;
}
.menu-13.expanded {
  max-height: 350px; /* Установите максимальную высоту, подходящую для вашего контента */
  transition: max-height 2.06s ease;
}
.menu-13.expanded .pr-menu-visible img{
  rotate:180deg;
}
.pr-menu{
  display: flex;
  flex-direction: column;
}
.pr-line{
  width: 80svw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 40px;
}
.pr-menu-visible{
  display: flex;
  justify-content: space-between;
  height: 100px;
}
.pr-list-price{
  text-align: right;
  line-height: 30px;
}
.pr-list-pricestat{
  text-align: right;
  line-height: 30px;
  font-family: 'Montserrat-SemiBold';
}
.pr-list-name{
  text-align: left;
  line-height: 30px;
}
.pr-list-namestat{
  text-align: left;
  line-height: 30px;
  font-family: 'Montserrat-SemiBold';
}

.pr-menu-visible h2{
  margin: 0;
  padding: 0;
  align-self: center;
  padding-left: 100px;
  font-size: 20px;
}
.pr-menu-visible img{
  margin-right: 100px;
  width: 40px;
  height: auto;
  transition: all 1s ease;
}
.menu.expanded .pr-menu-visible img{
  rotate:180deg;
}

.pr-menu button{
  border: 0px;
  background-color: #ffffff00;
}
.pr-hair-full-price{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  line-height: 60px;
  color: black;
  font-size: 20px;
}
.pr-hair-full-price h3{
  font-family: "Montserrat-Medium";

}
.pr-diva{
  margin-top: 8px;

}
.pr-diva a{
  font-size: 18px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: color 0.5s ease;
  cursor: pointer;
}
.pr-divht{
  flex: 1;
  background-color: #ffffff00;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;

  height: 1px;
  border-radius: 10px;
  transition: all 0.5s ease;

}

.pr-diva.active .pr-divht {
  background-color: rgba(255, 255, 255);
}



@media (max-width:768px) {
  .pr-content {
    margin: 35px 0svw 5svw 0svw;
  }
  .pr-menu-visible h2{
    padding-left: 40px;
    font-size: 20px;
  }
  .pr-menu-visible img{
    margin-right: 40px;
    width: 40px;
    height: auto;
    transition: all 1s ease;
  }
  .pr-line{
    width: 90svw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 40px;
  }
  .pr-hair-full-price{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    line-height: 60px;
    color: black;
    font-size: 17px;
  }
  .pr-switch{
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    width: 90svw;
    margin-right: auto;
    margin-left: auto;
    height: auto;
    text-align: center;
    gap: 100px;
  }
  .pr-diva a{
    font-size: 16px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    transition: color 0.5s ease;
    cursor: pointer;
  }
}
@media (max-width:670px) {
 
  .menu-4.expanded {
    max-height: 2200px; /* Установите максимальную высоту, подходящую для вашего контента */
  }
  .menu-3.expanded {
    max-height: 1600px; /* Установите максимальную высоту, подходящую для вашего контента */
  }  
  .menu-2.expanded {
    max-height: 1000px; /* Установите максимальную высоту, подходящую для вашего контента */
  }
  .menu-6.expanded {
    max-height: 800px; /* Установите максимальную высоту, подходящую для вашего контента */
  }
  .menu-10.expanded {
    max-height: 2200px; /* Установите максимальную высоту, подходящую для вашего контента */
  }
}
@media (max-width:500px) {
  .menu-7.expanded {
    max-height: 1600px; /* Установите максимальную высоту, подходящую для вашего контента */
  }
  .menu-1.expanded {
    max-height: 1000px; /* Установите максимальную высоту, подходящую для вашего контента */
  }
}
@media (max-width:410px) {
  .pr-switch{
    gap:17svw;
  }
  .pr-diva a{
    font-size: 13px;}
  .pr-switch{
    width: 96svw;
  }
  .pr-divht{
    flex: 1;
    background-color: #ffffff00;
    width: 80%;}
  .pr-menu-visible h2{
    padding-left: 30px;
    font-size: 17px;
  }
  .pr-menu-visible img{
    margin-right: 30px;
    width: 30px;}

  .pr-hair-full-price{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    line-height: 60px;
    color: black;
    font-size: 15px;
  }
}

@media (max-width:340px) {
  .pr-switch{
    gap:10px;
  }
  .pr-divht{
    flex: 1;
    background-color: #ffffff00;
    width: 80%;}
}
@media (max-width:340px) {
  .pr-switch{
    gap:10px;
  }
}