.ft-body{
    height: 850px;
    width: 100%;
    background-color: #474747;
    font-family: "Montserrat";
}
.ft-content{
    padding-top: 50px;
    height: 800px;
    width: 1320px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between ;
    display: flex;
}
.map-frame{
    width: 750px;
    height: 750px;
    position: relative;
    border-radius: 30px;
    border: 0px;
}

.ft-info{
    width: 35%;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
}
.ft-item{
    display: flex;
    flex-direction: column;
}
.div-h{
    color:#ffffffb0;
    font-size: 24px;
    padding-bottom: 20px;
    margin: 0;
}
.div-a{
    font-family: 'Montserrat-Light';
    color: #ffffffb0;
    font-size: 18px;
    justify-self: center;
}
.a-href{
    font-family: 'Montserrat-Light';
    color: #ffffffb0;
    font-size: 18px;
    justify-self: center;
    text-decoration: none;
    align-self: center;
    margin-left: 10px;

}
.metro{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.wimg{
    width: 30px;
}
.ft-line{
    display: flex;
}
.ft-bd{
    align-self: center;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    margin-right: 10px;
}
.red{
    background-color: #BF2727;
}
.blue{
    background-color: #2A3DA1;
}

@media (max-width:1500px) {
    .ft-body{
        height: 750px;
    }
    .ft-content{
        padding-top: 50px;
        height: 650px;
        width: 950px;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between ;
        display: flex;
    }
    .map-frame{
        width: 600px;
        height: 600px;
        position: relative;
        border-radius: 30px;
        border: 0px;
    }
    .ft-info{
        width: 32%;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;    
    }
    .ft-item{
        display: flex;
        flex-direction: column;
    }
    .div-h{
        color:#ffffffb0;
        font-size: 20px;
        padding-bottom: 10px;
        margin: 0;
    }
    .div-a{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 16px;
        justify-self: center;
    }
    .a-href{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 16px;
        justify-self: center;
        text-decoration: none;
        align-self: center;
        margin-left: 10px;
    }
    .metro{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ft-line{
        display: flex;
    }
    .ft-bd{
        align-self: center;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        margin-right: 10px;
    }
}
@media (max-width:1024px) {
    .ft-body{
        height: 750px;
    }
    .ft-content{
        padding-top: 50px;
        height: 650px;
        width: 700px;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between ;
        display: flex;
    }
    .map-frame{
        width: 400px;
        height: 600px;
        position: relative;
        border-radius: 30px;
        border: 0px;
    }
    .ft-info{
        width: 39%;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;    
    }
    .ft-item{
        display: flex;
        flex-direction: column;
    }
    .div-h{
        color:#ffffffb0;
        font-size: 18px;
        padding-bottom: 10px;
        margin: 0;
    }
    .div-a{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 16px;
        justify-self: center;
    }
    .a-href{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 16px;
        justify-self: center;
        text-decoration: none;
        align-self: center;
        margin-left: 10px;
    }
    .metro{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ft-line{
        display: flex;
    }
    .ft-bd{
        align-self: center;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        margin-right: 10px;
    }
}
@media (max-width:768px) {
    .ft-body{
        height: 100svw;
    }
    .ft-content{
        padding-top: 50px;
        max-height: 79svw;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between ;
        display: flex;
    }
    .map-frame{
        width: 52svw;
        height: 79svw;
        position: relative;
        border-radius: 30px;
        border: 0px;
    }
    .ft-info{
        width: 39%;
        height: 79svw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;    
    }
    .ft-item{
        display: flex;
        flex-direction: column;
    }
    .div-h{
        color:#ffffffb0;
        font-size: 2.3svw;
        padding-bottom: 1.3svw;
        margin: 0;
    }
    .div-a{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 2svw;
        justify-self: center;
    }
    .a-href{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 2svw;
        justify-self: center;
        text-decoration: none;
        align-self: center;
        margin-left: 1.3svw;
    }
    .metro{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ft-line{
        display: flex;
    }
    .ft-bd{
        align-self: center;
        height: 1.4svw;
        width: 1.4svw;
        border-radius: 50%;
        margin-right: 1.3svw;
    }
    .wimg{
        width: 3.9svw;
    }
}
@media (max-width:580px) {
    .ft-body{
        height: 100svw;
    }
    .ft-content{
        padding-top: 50px;
        height: 100svw;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        display: flex;
    }
    .map-frame{
        width: 45svw;
        height: 79svw;
        position: relative;
        border-radius: 3.9svw;
        border: 0px;
    }
    .ft-info{
        width: 43svw;
        height: 79svw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;    
    }
    .ft-item{
        display: flex;
        flex-direction: column;
    }
    .div-h{
        color:#ffffffb0;
        font-size: 3.3svw;
        padding-bottom: 1.3svw;
        margin: 0;
    }
    .div-a{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 2.5svw;
        justify-self: center;
    }
    .a-href{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 2.5svw;
        justify-self: center;
        text-decoration: none;
        align-self: center;
        margin-left: 1.3svw;
    }
    .metro{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ft-line{
        display: flex;
    }
    .ft-bd{
        align-self: center;
        height: 1.4svw;
        width: 1.4svw;
        border-radius: 50%;
        margin-right: 1.3svw;
    }
}
@media (max-width:440px) {
    .ft-body{
        height: 120svw;
    }
    .ft-content{
        padding-top: 50px;
        height: 120svw;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        display: flex;
    }
    .map-frame{
        width: 40svw;
        height: 100svw;
        position: relative;
        border-radius: 3.9svw;
        border: 0px;
    }
    .ft-info{
        width: 46svw;
        height: 100svw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;    
    }
    .ft-item{
        display: flex;
        flex-direction: column;
    }
    .div-h{
        color:#ffffffb0;
        font-size: 3.9svw;
        padding-bottom: 1.3svw;
        margin: 0;
    }
    .div-a{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 3svw;
        justify-self: center;
    }
    .a-href{
        font-family: 'Montserrat-Light';
        color: #ffffffb0;
        font-size: 3svw;
        justify-self: center;
        text-decoration: none;
        align-self: center;
        margin-left: 1.3svw;
    }
    .metro{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ft-line{
        display: flex;
    }
    .ft-bd{
        align-self: center;
        height: 1.4svw;
        width: 1.4svw;
        border-radius: 50%;
        margin-right: 1.3svw;
    }
    .wimg{
        width: 5svw;
    }
}