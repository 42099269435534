.HashLink{
    text-decoration: none;

}
.infomb-box{
    height: 500px;
    background-image: url('../../assets/brandfull.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-color: #474747;
    display: flexbox;
    flex-direction: column;
    border: 1px solid #47474700;
}

.infomb-about{
    margin-top: 50px;
    display: flexbox;
    flex-direction: column;
    align-items: center;
}
.infomb-about h1{
    margin-left: 40px;
    margin-right: 40px;
    font-size: 35px;
    font-family: 'Montserrat-Light';
    color:white;
    text-align: left;
    margin-bottom: 20px;
}

.mbdivhr{
    width: 50svw;
    flex: 1;
    background-color: white;
    margin-left: 40px;
    height: 2px;
    border-radius: 10px;
}

.firstmb-about{
    width: 84svw;
    display: flex; 
    flex-direction: column;
    justify-content: space-around; 
    margin-top: 15px;
    color:white;
    margin-left: auto;
    margin-right: auto;
}
.secondmb-about{
    width: 80%;
    display: flex; 
    justify-content: space-around; 
    margin-top: 50px;
    color:white;
    margin-left: auto;
    margin-right: auto;
}
.mbdiva{
    margin-top: 20px;
    text-decoration: none;

}
.mbdiva a{
    font-size: 18px;
    text-decoration: none;
    color: rgb(199, 199, 199);
    transition: color 0.5s ease;
    cursor: pointer;
    text-decoration: none;

}
.mbdivht{
    flex: 1;
    background-color: #ffffff00;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    border-radius: 10px;
    transition: all 0.5s ease;

}

.mbdiva:hover a{
    color:#ffffff;

}
.mbdiva:hover .divht{
    background-color: rgba(255, 255, 255);

}
@media (max-width:630px) {
    .infomb-box{
        background-size: auto 100%;
    }
}
@media (max-width:540px) {
    .mbdiva a{
        font-size: 16px;
        text-decoration: none;
        color: rgb(199, 199, 199);
        transition: color 0.5s ease;
        cursor: pointer;
    }
    .mbdivhr{
        width: 70svw;
        flex: 1;
        background-color: white;
        margin-left: 25px;
        margin-right: 25px;
        height: 2px;
        border-radius: 10px;
    }
    .infomb-about h1{
        margin-left: 25px;
        margin-right: 25px;

        font-size: 27px;
        font-family: 'Montserrat-Light';
        color:white;
        text-align: left;
        margin-bottom: 20px;
    }
    .infomb-box{
        height: 450px;
        background-image: url('../../assets/brandfull.png');
        background-repeat: no-repeat;
        background-position: center;
        background-color: #474747;
        display: flexbox;
        flex-direction: column;
        border: 1px solid #47474700;
    }
    .firstmb-about{
        width: 84svw;
        display: flex; 
        flex-direction: column;
        justify-content: space-around; 
        margin-top: 15px;
        color:white;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width:450px) {
    .mbdiva a{
        font-size: 15px;
    }
    .infomb-about h1{
        font-size: 21px;
    }
    .mbdivhr{
        width: 70svw;
        flex: 1;
        background-color: white;
        margin-left: 25px;
        margin-right: 25px;
    }
    .infomb-box{
        height: 430px;}
}

/* _______________________________________________________________ */
.info-box{
    height: 390px;
    background-image: url('../../assets/brand.png');
    background-repeat: no-repeat;
    background-size: auto 160%;
    background-position: center;
    background-color: #474747;
    display: flexbox;
    flex-direction: column;
    border: 1px solid #47474700;
}
.info-about{
    margin-top: 50px;
    display: flexbox;
    flex-direction: column;
    align-items: center;
}
.info-about h1{
    font-size: 35px;
    font-family: 'Montserrat-Light';
    color:white;
    text-align: center;
    margin: 25px;
    margin-bottom: 20px;
}

.divhr{
    width: 820px;
    flex: 1;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    border-radius: 10px;
}
.first-about{
    width: 80%;
    display: flex; 
    justify-content: space-around; 
    margin-top: 80px;
    color:white;
    margin-left: auto;
    margin-right: auto;
}
.second-about{
    width: 80%;
    display: flex; 
    justify-content: space-around; 
    margin-top: 50px;
    color:white;
    margin-left: auto;
    margin-right: auto;
}
.diva a{
    font-size: 18px;
    text-decoration: none;
    color: rgb(199, 199, 199);
    transition: color 0.5s ease;
    cursor: pointer;
}
.divht{
    flex: 1;
    background-color: #ffffff00;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    border-radius: 10px;
    transition: all 0.5s ease;

}

.diva:hover a{
    color:#ffffff;

}
.diva:hover .divht{
    background-color: rgba(255, 255, 255);

}
@media (max-width:1350px) {
    .diva a{
        font-size: 17px;
    }
    .info-about h1{
        font-size: 32px;
    }
    .divhr{
        width: 760px;
    }
}
@media (max-width:1024px) {
    .diva a{
        font-size: 17px;
    }
    .info-about h1{
        font-size: 30px;
    }
    .divhr{
        width: 690px;
    }
}