.sl-body{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.sl-box{
    width: 100%;
    height: auto;
    /* background-color: red; */
    text-align: center;
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    color:#474747;
}
.sl-box h1{
    color: black;
    font-family: 'Montserrat';
    margin: 0;
    padding: 70px;
    font-size: 40px;
}
.sl-content{
    width: 1350px;
    height: auto;
    /* background-color: #3cff00; */
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;

}
.sl-prim{
    margin-left: auto;
    margin-right: auto;
    width: 1350px;
    text-align: justify;
    margin-bottom: 20px;
}
.sl-prim a{
    font-family: 'Montserrat';
    color: #a5a5a5;
    font-size: 25px;
}
.sl-desctop-img{
    border-radius: 20px;
    min-width: 680px;
    max-width: 680px;
    min-height: 740px;
    max-height: 740px;
    display: block;
}
.sl-mobile-img{
    width: 100%;
    height: auto;
    display: none;
}
.sl-text{
    font-family: 'Montserrat';
    margin-left: 55px;
    /* background-color: yellow; */
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    height: 740px;
}

.sl-atext{
    height: 590px;
    text-align: justify;
}
.sl-text a{
    font-size: 25px;
    line-height: 47px;
    text-align: justify;
}

.sl-text h2{
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 35px;
    color:#474747;

}
.sl-text button{
    width: 350px;
    height: 75px;
    border-radius: 39px;
    border: 1px solid black;
    background-color: #47474700;
    justify-self: flex-end;
    font-family: 'Montserrat';
    font-size: 27px;
    transition: all 1s ease;
    color:#474747;
    margin-top: 15px;
}
.sl-text button:hover{
    background-color: #474747;
    color:white;
}
@media (max-width:1500px) {
    .sl-box{
        height: auto;
    }
    .sl-box h1{
        color: black;
        font-family: 'Montserrat';
        margin: 0;
        padding: 70px;
        font-size: 35px;
    }
    .sl-content{
        width: 950px;
        height: 100%;
        /* background-color: #3cff00; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }
    .sl-prim{
        width: 950px;
    }
    .sl-prim a{
        font-size: 20px;
    }
    .sl-desctop-img{
        border-radius: 20px;
        min-width: 450px;
        max-width: 450px;
        min-height: 490px;
        max-height: 490px;
    }
    .sl-text{
        margin-left: 30px;
        height: 490px;
    }

    .sl-atext{
        height: 380px;
    }
    .sl-text a{
        font-size: 20px;
        line-height: 25px;
        text-align: justify;
    }

    .sl-text h2{
        margin-top: 0px;
        margin-bottom: 15px;
        font-size: 28px;
        color:#474747;

    }
    .sl-text button{
        width: 250px;
        height: 50px;
        border-radius: 39px;
        border: 1px solid black;
        background-color: #47474700;
        justify-self: flex-end;
        font-family: 'Montserrat';
        font-size: 22px;
        transition: all 1s ease;
        color:#474747
    }
}
@media (max-width:1024px) {
    .sl-box{
        height: auto;
    }
    .sl-box h1{
        color: black;
        font-family: 'Montserrat';
        margin: 0;
        padding: 70px;
        font-size: 35px;
    }
    .sl-content{
        width: 718px;
        height: auto;
        /* background-color: #3cff00; */
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }
    .sl-prim{
        width: 718px;
        margin-top: 90px;
    }
    .sl-prim a{
        font-size: 20px;
    }
    .sl-desctop-img{
        border-radius: 20px;
        min-width: 350px;
        max-width: 350px;
        min-height: 381px;
        max-height: 381px;
    }
    .sl-text{
        margin-left: 25px;
        height: 490px;
    }

    .sl-atext{
        width: 388px;
        height: 380px;
    }
    .sl-text a{
        font-size: 20px;
        line-height: 28px;
        text-align: justify;
    }
    .pzdc{
        min-width: 718px;
        position: relative;
        left: -380px;
        top: -10px
    }
    .pzdc1{
        max-width: 340px;
        position: relative;
        left: 0px;
    }
    .sl-text h2{
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 28px;
        color:#474747;

    }
    .sl-text button{
        position: relative;
        left:100px;
        top:30px;
        width: 250px;
        height: 50px;
        border-radius: 39px;
        border: 1px solid black;
        background-color: #47474700;
        justify-self: flex-end;
        font-family: 'Montserrat';
        font-size: 22px;
        transition: all 1s ease;
        color:#474747
    }
}
@media (max-width:768px) {
    .sl-body h1{
        font-size: 30px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .sl-box{
        height: auto;
    }
    .sukablat{
        margin-left: auto;
        margin-right: auto; 
    }
    .sl-content{
        flex-direction: column;
        margin-left: 4svw;
        margin-right: 4svw;
        width: auto;
        height: auto;
    }
    .sl-prim{
        width: auto;
        margin-left: 4svw;
        margin-right: 4svw;
        margin-top: 0;
    }
    .sl-prim a{
        font-size: 23px;
    }
    .sl-desctop-img{
        display: none;
    }
    .sl-mobile-img{
        display: block;
    }
    .pzdc{
        width: 92svw;
        min-width: 92svw;
        max-width: 92svw;
        position: static;
    }
    .pzdc1{
        width: 92svw;
        min-width: 92svw;
        max-width: 92svw;
        position: static;
        left: 0px;
        top: 0px;
        margin-top: 30px;
    }
    .sl-atext{
        width: 92svw;
        height: auto;
        text-align: justify;
        padding: 0;
    }
    .sl-text{
        margin: 0;
        width: 92svw;
        height: auto;
        position: relative;
    }
    .sl-text a{
        font-size: 23px;
        line-height: 45px;
        text-align: justify;
        position: relative;
    }
    .sl-text h2{
        width: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 25px;
        color:#474747;
        text-align: left;
        margin-top: 40px;
    }
    .sl-text button{
        width: 92svw;
        height: 75px;
        border-radius: 39px;
        font-size: 23px;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        padding: 0;   
        position: relative;
        left:initial;
        right: initial;
        top: auto;
        margin-top: 30px;

    }
    .sl-text button:hover{
        background-color: #474747;
        color:white;
    }
}
@media (max-width:480px) {
    .sl-text a{
        font-size: 19px;
        line-height: 33px;
    }
    .sl-prim a{
        font-size: 19px;

    }
    .sl-text button{
        width: 92svw;
        height: 55px;
        border-radius: 39px;
        font-size: 19px;
        margin-top: 30px;
    }
    .sl-body h1{
        font-size: 24px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
@media (max-width:350px) {
    .sl-text a{
        font-size: 17px;
        line-height: 30px;
    }
    .sl-text button{
        width: 92svw;
        height: 60px;
        border-radius: 39px;
        font-size: 17px;
        margin-top: 30px;
    }
    .sl-body h1{
        font-size: 24px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}