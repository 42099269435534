.fcmb-box{
    background-color: #474747;
    background-image: url('../../assets/bgmbImg.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 25%;
    width: 100%;
    height: auto;
    min-height: auto;
    font-family: 'Montserrat';
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.fcmb-text{
    font-size: 9svw;
    text-align: center;
    margin-top: 10svh;
}
.fcmb-text h1{
    margin: 0;
    padding: 0;
}
.fcmb-button{
    margin-top: 4svh;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 50px;
}
.fcmb-button b{
    font-size: 8svw;
}
.fcmb-button a{
    font-size: 4svw;
    padding: 0;
    margin: 0;
    line-height: 3.9svw;
}
.fcmb-button button{
    margin-top: 10px;
    font-family: 'Montserrat';
    width: 50svw;
    height: 60px;
    background-color: #eb7a0900;
    border: 1px solid white;
    color: white;
    font-size: 4svw;
    border-radius: 40px;
    transition: all 0.9s ease;
    margin-left: auto;
    margin-right: auto;
}
.fcmb-button button:hover{
    background-color: #ffffff;
    color: #000000;
}
@media (max-width:584px) {
    .fcmb-text{
        font-size: 9svw;
        text-align: center;
        margin-top: 16svh;
    }
    .fcmb-button{
        margin-top: 8svh;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .fcmb-button b{
        font-size: 10svw;
    }
    .fcmb-button a{
        font-size: 5svw;
        padding: 0;
        margin: 0;
        line-height: 6svw;
    }
    .fcmb-button button{
        margin-top: 9px;
        width: 50svw;
        height: 60px;
        background-color: #eb7a0900;
        border: 1px solid white;
        color: white;
        font-size: 5svw;
        border-radius: 40px;
        transition: all 0.9s ease;
        margin-left: auto;
        margin-right: auto;
    }
}
/* ___________________________________________________ */
.fc-box{
    width: 100%;
    height: 92.4svh;
    background-color: #474747;
    background-image: url('../../assets/bgImg.png');
    background-repeat: no-repeat;
    background-size: auto 120%;
    background-position: center;
    font-family: 'Montserrat';
    color: white;
    display: flex;
    flex-direction: column;
}

.fc-top{
    height: auto;
    padding-top: 8svw;
    width: 85%;
    display: flex;
    align-content: flex-end;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}
.fc-name{
    margin: 0;
    padding: 0;
    font-size: 190px;
}
.fc-button{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.fc-button b{
    font-size: 50px;
}
.fc-button a{
    font-size: 26px;
    padding: 0;
    margin: 0;
}
.fc-button button{
    margin-top: 20px;
    font-family: 'Montserrat';
    width: 100%;
    height: 80px;
    background-color: #eb7a0900;
    border: 2px solid white;
    color: white;
    font-size: 26px;
    border-radius: 40px;
    transition: all 0.9s ease;
}
.fc-button button:hover{
    background-color: #ffffff;
    color: #000000;
}
.fc-center{
    padding:0;
    margin: 0;
    height: auto;
    display: flex;
    align-items: center;
    margin-left: 15svw;
}
.fc-bottom{
    margin-left: 10svw;
    height: max-content;
    display: flex;
    align-items: flex-end;
    padding-bottom: auto;
    flex-grow: 3;
}
.fc-bottom a{
    font-size: 26px;
    margin-bottom: 40px;
}

@media (max-width: 1800px) {
    .fc-name{
        font-size: 170px;
    }
    .fc-bottom a{
        font-size: 26px;
        margin-bottom: 35px;
    }
    .fc-top{
        padding-top: 18svh;
    }
    .fc-button b{
        font-size: 50px;
    }
    .fc-button a{
        font-size: 26px;
    }
    .fc-button button{
        margin-top: 20px;
        height: 80px;
        font-size: 26px;
        border-radius: 40px;
    }
}
@media (max-width: 1600px) {
    .fc-name{
        font-size: 150px;
    }
    .fc-bottom a{
        font-size: 24px;
        margin-bottom: 40px;
    }
    .fc-top{
        padding-top: 16svh;
    }
    .fc-button b{
        font-size: 45px;
    }
    .fc-button a{
        font-size: 24px;
    }
    .fc-button button{
        margin-top: 1svh;
        height: 70px;
        font-size: 24px;
        border-radius: 35px;
    }
}
@media (max-width: 1400px) {
    .fc-name{
        font-size: 127px;
    }
    .fc-bottom a{
        font-size: 24px;
        margin-bottom: 40px;
    }
    .fc-top{
        padding-top: 15svh;
    }
    .fc-button b{
        font-size: 40px;
    }
    .fc-button a{
        font-size: 21px;
    }
    .fc-button button{
        margin-top: 10px;
        height: 64px;
        font-size: 22px;
        border-radius: 32px;
    }
    .fc-box{
        width: 100%;
        height: 92.4svh;
        background-color: #474747;
        background-image: url('../../assets/bgImg.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
        font-family: 'Montserrat';
        color: white;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 1200px) {
    .fc-name{
        font-size: 109px;
    }
    .fc-bottom a{
        font-size: 22px;
        margin-bottom: 40px;
    }
    .fc-top{
        padding-top: 16svh;
    }
    .fc-button b{
        font-size: 36px;
    }
    .fc-button a{
        font-size: 18px;
    }
    .fc-button button{
        margin-top: 9px;
        height: 60px;
        font-size: 20px;
        border-radius: 30px;
    }
}
@media (max-width: 1024px) {
    .fc-name{
        font-size: 100px;
    }
    .fc-bottom a{
        font-size: 20px;
        margin-bottom: 60px;
    }
    .fc-top{
        padding-top: 17svh;
    }
    .fc-button b{
        font-size: 33px;
    }
    .fc-button a{
        font-size: 16px;
    }
    .fc-button button{
        margin-top: 9px;
        height: 54px;
        font-size: 19px;
        border-radius: 27px;
    }
}
@media (max-width: 940px) {
    .fc-name{
        font-size: 81px;
    }
    .fc-bottom a{
        font-size: 20px;
        margin-bottom: 60px;
    }
    .fc-top{
        padding-top: 22svh;
    }
    .fc-button b{
        font-size: 30px;
    }
    .fc-button a{
        font-size: 16px;
    }
    .fc-button button{
        margin-top: 5px;
        height: 48px;
        font-size: 17px;
        border-radius: 24px;
    }
}
@media (max-width: 768px) {
    .fc-top{
    align-items: flex-end;
    }
    .fc-name{
        font-size: 65px;
    }
    .fc-bottom a{
        font-size: 18px;
        margin-bottom: 10svh;
    }
    .fc-top{
        padding-top: 25svh;
    }
}
@media (max-width: 600px) {
    .fc-name{
        font-size: 70px;
    }
}
@media (max-width: 100px) {
    .fc-name{
        font-size: 10px;
    }
}