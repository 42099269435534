.invmb-box{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.invmb-box h1{
    font-family: 'Montserrat';
    font-size: 40px;
    text-align: center;
}

.invmb-content{
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-template-rows: 1.9fr 1fr 2fr;
    gap: 10px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
}

.invmb-item1{
    grid-row-start: 1;
    grid-row-end: 3;
}
.invmb-item2{
    grid-row-start: 1;
    grid-row-end: 2;
}.invmb-item3{
    grid-row-start: 2;
    grid-row-end: 3;
}.invmb-item4{
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
}
.invmb-img{
    height: 100%;
    width: 100%;
}
@media (max-width:580px) {
    .invmb-box h1{
        font-size: 35px;
    }
    .invmb-content{
        width: 400px;
    }
}
@media (max-width:460px) {
    .invmb-box h1{
        font-size: 33px;
    }
    .invmb-content{
        width: 330px;
    }
}
@media (max-width:380px) {
    .invmb-box h1{
        font-size: 31px;
    }
    .invmb-content{
        width: 280px;
    }
}
@media (max-width:320px) {
    .invmb-box h1{
        font-size: 9svw;
    }
    .invmb-content{
        width: 80svw;
    }
}


/* _____________________________________________________________________ */
.int-body{
    width: 100%;
    height: auto;
    padding-bottom: 100px;
}


.img-pole{
    display: flex;
    width: 1400px;
    height: 717px;
    margin-left: auto;
    margin-right: auto;
    gap: 14px;
    overflow: hidden;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.int-body h1{
    font-family: 'Montserrat';
    text-align: center;
    margin: 0;
    padding-top: 80px;
    padding-bottom: 70px;
    font-size: 40px;
}

.first-item-img{
    min-width: 560px;
    max-width: 560px;
    min-height: 717px;
    max-height: 717px;
    overflow: hidden;
}

.second-item-img{
    width: 826px;
    height: 717px;

    display: flex;
    flex-direction: column;
    
}

.third-item-img{
    height: 340px;
    overflow: hidden;

}

.fourth-item-img{
    margin-top: 16px;
    height: 450px;
    display: flex;
    gap: 14px;
    overflow: hidden;
}

.fifth-item-img{
    flex: 1;
    overflow: hidden;
}
.sixth-item-img img{
    height: auto;
}

.sixth-item-img{
    flex: 2;
    overflow: hidden;
    height: auto;
}
.img-pole img{
    min-width: 100%;
    min-height: 100%;
}
.int-text{
    margin-top: 16px;
    width: 1400px;
    margin-left: auto;
    margin-right: auto;
    height: 160px;
    background-color: #474747;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
.int-text1{
    padding-top: 15px;
    color: white;
    font-family: 'Montserrat-Light';
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
    line-height: 40px;
    text-align: justify;
}
.img-pole img{
    max-width: 100%;
    max-height: 100%;
}
@media (max-width:1500px) {
    .int-body{
        height: 830px;
        padding-bottom: 0px;
    }
    .img-pole{
        width: 924px;
        height: 473px;
        gap: 14px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }
    
    .int-body h1{
        padding-top: 60px;
        padding-bottom: 40px;
        font-size: 35px;
    }
    
    .first-item-img{
        min-width: 367px;
        max-width: 367px;
        min-height: 473px;
        max-height: 473px;
    }
    .int-text{
        margin-top: 16px;
        width: 924px;
        height: 150px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .second-item-img{
        width: 557px;
        height: 473px;
        display: flex;
        flex-direction: column;   
    }
    .int-text1{
        padding-top: 15px;
        width: 90%;
        font-size: 18px;
        line-height: 28px;
    }
}
@media (max-width:1024px) {
    .int-body{
        height: 670px;
        padding-bottom: 50px;
        gap: 0px;

    }
    .img-pole{
        width: 718px;
        height: 369px;
        gap: 10px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }
    .third-item-img{
        min-height: 151px;
        max-height: 151px;
        overflow: hidden;
    
    }
    .int-body h1{
        padding-top: 40px;
        padding-bottom: 50px;
        font-size: 35px;
    }
    
    .first-item-img{
        min-width: 286px;
        max-width: 286px;
        min-height: 369px;
        max-height: 369px;
    }
    .int-text{
        margin-top: 10px;
        width: 718px;
        height: 170px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .fourth-item-img{
        min-height: 206px;
        max-height: 206px;
        gap: 10px;
        margin-top: 10px;
    }
    .second-item-img{
        width: 557px;
        height: 473px;
        display: flex;
        flex-direction: column;   
    }
    .int-text1{
        padding-top: 10px;
        width: 90%;
        font-size: 18px;
        line-height: 28px;
    }
}