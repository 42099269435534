html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat-Black';
  src: local('Montserrat-Black'),
    url(./fonts/Montserrat-Black.ttf) format('truetype');
  font-weight: bold;
}

.font-montserrat-regular{
  font-family: "Montserrat";
}

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'),
    url(./fonts/Montserrat-Light.ttf) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'),
    url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'),
    url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Jura';
  src: local('Jura'),
    url(./fonts/Jura-Light.ttf) format('truetype');
  font-weight: bold;
}
::-webkit-scrollbar {
  width: 10px;
  height: 50000px;
  background-color: #dddddd;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #474747;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #dddddd;
}
/* 

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: transparent;
}
::-webkit-scrollbar-track-piece:start {
  background: transparent url('./assets/scrollbar.png') repeat-y !important;
}
::-webkit-scrollbar-track-piece:end {
  background: transparent url('./assets/scrollbar.png') repeat-y !important;
} */