
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.font-montserrat-regular {
  font-family: 'Montserrat';

}
.font-montserrat-light{
  font-family: 'Montserrat-Light';
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.item-1 {
  background-color:aqua;
}

.item-2 {
  background-color: blueviolet;
}

.item-3{
  background-color: blue;
}

.info-hr {
  margin-top: -7%;
  width: 32%;
  margin-left: 34%;
  border-top: 4  px solid black;
}
.hm-au-div h1{
  margin: 0;
  font-size: 40px;
  padding-top: 90px;
  text-align: center;
  color: #000000;
  font-family: 'Montserrat';
}

.hm-au-div h2{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 20px;
  font-size: 30px;
  color: #474747AB;
  width: 40%;
  font-family: 'Montserrat';
}
@media (max-width:1500px) {
  
.hm-au-div h1{
  font-size: 35px;
  padding-top: 40px;
}

.hm-au-div h2{
  padding-top: 0px;
  font-size: 27px;
  width: 60%;
}
}
@media (max-width:1024px) {
  
  .hm-au-div h1{
    font-size: 30px;
    padding-top: 65px;
  }

  .hm-au-div h2{
    padding-top: 0px;
    font-size: 24px;
    width: 78%;
  }
}
@media (max-width:600px) {
  
  .hm-au-div h1{
    font-size: 25px;
    padding-top: 50px;
  }

  .hm-au-div h2{
    padding-top: 0px;
    font-size: 20px;
    width: 90svw;
  }
}