.carousel-mb {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.slides-container-mb {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: auto;
}

.slide-mb {
  min-width: 90%;
  max-width: 90%;
  padding: 5%;

  justify-content: center; /* Центрирование слайдов по горизонтали */
  align-items: center; /* Центрирование слайдов по вертикали */
}
.slide-mb-ad {
  min-width: 90%;
  max-width: 90%;
  padding: 5%;
  max-height: 400px;
  justify-content: center; /* Центрирование слайдов по горизонтали */
  align-items: center; /* Центрирование слайдов по вертикали */
}

.content-mb {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 30px;
  height: auto;
  min-width: 580px;
  max-width: 580px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.content-mb button{
  background-color: #47474700;
  height: 50px;
  border: 1px solid black;
  border-radius: 25px;
  margin-top: 10px;
  min-width: 580px;
  max-width: 580px;
  font-family: 'Montserrat';
  font-size: 21px;
}
.tmb-first-line a{
  text-justify: inherit;
  margin: 0;
}
.tmb-first-line-ad a{
  text-justify: inherit;
  margin: 0;
}
.tmb-text{
  font-size: 21px;
  font-family: 'Montserrat';
  text-align: left;
}
.tmb-info{
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 30px;
}
.bold-mb{
  font-family: 'Montserrat-SemiBold';
  margin-top: 0px;
}
.tmb-first-line img{
  width: 250px;
  height: auto;
  float:left;
  margin: 0px 30px 10px 0px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.tmb-first-line{
  overflow: hidden;
  border-top-left-radius: 30px;
  display: flex;
}
.tmb-first-line-ad img{
  width: 250px;
  height: auto;
  float:left;
  margin: 0px 30px 10px 0px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.tmb-first-line-ad{
  overflow: hidden;
  border-top-left-radius: 30px;
  display: flex;
}



.navigation-mb {
  position: relative;
  bottom: 10px;
  width: 100%;
  height: 60px;
}

.dots-mb {
  display: flex;
  gap: 50px;
  justify-content: center; /* Центрирование точек */
  position: relative;
  top: 20px;
}

.dot-mb {
  border: 4px solid #474747;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.dot-mb.active {
  background-color: #474747;
}

.order-button-mb{
  height: 55px;
  width: 219px;
  margin-right: 3%;
  margin-top: 200px;
  border-radius: 31px;
  font-size: 22px;
  font-family: 'Montserrat';
  background-color: #474747;
  color: white;
  border:  1px solid white;
  flex-shrink: 1;
  transition: all 0.5s ease;
}

.order-button-mb:hover{
  background-color: white;
  border-color: #474747;
  color:black;
}

.obrezka1-mb{
  margin-left: 75px;
  max-width: 740px;
  min-width: 740px;
  margin-bottom: 75px;
}

.obrezka2-mb{
  margin-right: 4svw;
  margin-left: 4svw;
  max-width: 940px;
  min-width: 940px;
  margin-bottom: 4svw;
}

.obrez-but-mb{
  margin-top: 55px;
  margin-right: 1.7svw;
  margin-left: 1svw;
}
@media (max-width:630px) {
  .content-mb {
    border-radius: 30px;
    min-width: 480px;
    max-width: 480px;
  }
  .tmb-first-line a{
    text-justify: inherit;
    margin: 0;
    font-size: 21px;
  }
  .tmb-first-line-ad a{
    text-justify: inherit;
    margin: 0;
    font-size: 21px;
  }
  .tmb-text{
    font-size: 21px;
    font-family: 'Montserrat';
    text-align: left;
    line-height: 24px;
  }
  .simple{
    margin-top: 37px;
  }
  .tmb-info{
    margin-right: 10px;
    margin-top: 37px;
    margin-bottom: 30px;
  }
  .bold-mb{
    font-family: 'Montserrat-SemiBold';
    margin-top: 0px;
  }
  .tmb-first-line img{
    width: 200px;
    margin: 0px 30px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .tmb-first-line-ad img{
    width: 200px;
    margin: 0px 30px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .content-mb button{
    background-color: #47474700;
    height: 50px;
    border: 1px solid black;
    border-radius: 25px;
    margin-top: 0px;
    min-width: 480px;
    max-width: 480px;
    font-size: 21px;

  }
  .dots-mb {
    display: flex;
    gap: 30px;
    justify-content: center; /* Центрирование точек */
    position: relative;
    top: 20px;
  }
  
  .dot-mb {
    border: 4px solid #474747;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }
}
@media (max-width:530px) {
  .content-mb {
    border-radius: 30px;
    min-width: 390px;
    max-width: 390px;
  }
  .tmb-first-line a{
    text-justify: inherit;
    margin: 0;
  }
  .tmb-first-line-ad a{
    text-justify: inherit;
    margin: 0;
  }
  .tmb-text{
    font-size: 19px;
    text-align: left;
    line-height: 24px;
  }
  .simple{
    margin-top: 37px;
  }
  .tmb-info{
    margin-right: 10px;
    margin-top: 37px;
    margin-bottom: 30px;
  }
  .tmb-first-line img{
    width: 150px;
    margin: 0px 20px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .tmb-first-line-ad img{
    width: 150px;
    margin: 0px 20px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .content-mb button{
    background-color: #47474700;
    height: 42px;
    border: 1px solid black;
    border-radius: 25px;
    margin-top: 0px;
    min-width: 390px;
    max-width: 390px;
    font-size: 20px;

  }
}
@media (max-width:450px) {
  .content-mb {
    border-radius: 30px;
    min-width: 310px;
    max-width: 310px;
  }
  .tmb-first-line a{
    text-justify: inherit;
    margin: 0;
  }
  .tmb-first-line-ad a{
    text-justify: inherit;
    margin: 0;
  }
  .tmb-text{
    font-size: 19px;
    text-align: left;
    line-height: 24px;
  }
  .simple{
    margin-top: 21px;
  }
  .tmb-info{
    margin-right: 10px;
    margin-top: 21px;
    margin-bottom: 30px;
  }
  .tmb-first-line img{
    width: 120px;
    margin: 0px 20px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .tmb-first-line-ad img{
    width: 120px;
    margin: 0px 20px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .content-mb button{
    background-color: #47474700;
    height: 42px;
    border: 1px solid black;
    border-radius: 25px;
    margin-top: 0px;
    min-width: 310px;
    max-width: 310px;
    font-size: 18px;
  }
}
@media (max-width:340px) {
  .content-mb {
    border-radius: 30px;
    min-width: 280px;
    max-width: 280px;
  }
  .slide-mb-ad{
    min-height: 450px;
  }
  .tmb-first-line a{
    text-justify: inherit;
    margin: 0;
  }
  .tmb-first-line-ad a{
    text-justify: inherit;
    margin: 0;
  }
  .tmb-text{
    font-size: 18px;
    text-align: left;
    line-height: 24px;
  }
  .simple{
    margin-top: 22px;
  }
  .first{
    max-width: 255px;
  }
  .tmb-info{
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .tmb-first-line img{
    width: 120px;
    margin: 0px 20px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .tmb-first-line-ad img{
    width: 116px;
    margin: 0px 10px 0px 0px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 0;
  }
  .content-mb button{
    background-color: #47474700;
    height: 42px;
    border: 1px solid black;
    border-radius: 25px;
    margin-top: 0px;
    min-width: 280px;
    max-width: 280px;
    font-size: 18px;

  }
}
/* ___________________________________________________________________________ */
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flexbox;
  flex-direction: column;
}

.slides-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 400px;

}

.slide {
  min-width: 80%;
  max-width: 80%;
  padding: 10%;

  display: flex;
  justify-content: center; /* Центрирование слайдов по горизонтали */
  align-items: center; /* Центрирование слайдов по вертикали */
}

.content {
  display: flex;
  background-color: #474747;
  border-radius: 30px;
  height: 400px;
  min-width: 1370px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.247);
}

.image {
  flex: 1;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  flex-shrink: 0;
  min-width: 300px;
  max-width: 300px;
}

.image img {
  width: auto;
  height: 100%;
}

.text {
  font-family: 'Montserrat-Light';
  color: white;
  text-align: left;
  padding: 0px;  
  flex-shrink: 3;
  width:max-content;
  align-self: flex-end;
  font-size: 25px;
}

.bold{
  font-family: 'Montserrat-SemiBold';
}

.navigation {
  position: relative;
  bottom: 10px;
  width: 100%;
  height: 60px;
  margin-top: 30px;
}

.dots {
  display: flex;
  gap: 20px;
  justify-content: center; /* Центрирование точек */
  position: relative;
  top: 20px;
}

.dot {
  border: 4px solid #474747;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #474747;
}

.order-button{
  height: 55px;
  width: 219px;
  margin-right: 3%;
  margin-top: 200px;
  border-radius: 31px;
  font-size: 22px;
  font-family: 'Montserrat';
  background-color: #474747;
  color: white;
  border:  1px solid white;
  flex-shrink: 1;
  transition: all 0.5s ease;
}

.order-button:hover{
  background-color: white;
  border-color: #474747;
  color:black;
}

.obrezka1{
  margin-left: 75px;
  max-width: 740px;
  min-width: 740px;
  margin-bottom: 75px;
}

.obrezka2{
  margin-right: 4svw;
  margin-left: 4svw;
  max-width: 940px;
  min-width: 940px;
  margin-bottom: 4svw;
}

.obrez-but{
  margin-top: 55px;
  margin-right: 1.7svw;
  margin-left: 1svw;
}
@media (max-width:1500px) {
  .image {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    min-width: 240px;
    max-width: 240px;
  }
  .content {
    border-radius: 30px;
    height: 330px;
    min-width: 1150px;
  }
  .text {
    font-size: 23px;
  }
  .obrezka1{
    margin-left: 50px;
    max-width: 600px;
    min-width: 600px;
    margin-bottom: 30px;
  }
  .order-button{
    height: 55px;
    width: 219px;
    margin-right: 0;
    margin-top: 200px;
    border-radius: 31px;
    font-size: 22px;
  }
  .obrez-but{
    margin-top: 20px;
    margin-right: 1svw;
    margin-left: 1svw;
  }
  .obrezka2{
    margin-right: 50px;
    margin-left: 50px;
    max-width: 800px;
    min-width: 800px;
    margin-bottom: 30px;
  }
  .slides-container {
    height: 380px;
  }
  .dots {
    gap: 15px;
  }
  
  .dot {
    border: 4px solid #474747;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }
  .navigation {
    margin-top: 0px;
  }
} 
@media (max-width:1250px) {
  .image {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    min-width: 240px;
    max-width: 240px;
  }
  .content {
    border-radius: 30px;
    height: 300px;
    min-width: 900px;
  }
  .text {
    font-size: 19.3px;
  }
  .obrezka1{
    margin-left: 35px;
    max-width: auto;
    min-width: auto;
    margin-bottom: 30px;
  }
  .obrezka2{
    margin-right: 35px;
    margin-left: 35px;
    max-width: auto;
    min-width: auto;
    margin-bottom: 30px;
  }
  .order-button{
    height: 50px;
    width: 180px;
    margin-right: 0;
    margin-top: 200px;
    border-radius: 31px;
    font-size: 18px;
  }
  .obrez-but{
    margin-top: 20px;
    margin-right: 1svw;
    margin-left: 1svw;
  }
  .slides-container {
    height: 340px;
  }
}
  @media (max-width:1024px) {
    .image {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      min-width: 160px;
      max-width: 160px;
    }
    .content {
      border-radius: 30px;
      height: 240px;
      min-width: 700px;
    }
    .text {
      font-size: 16px;
    }
    .obrezka1{
      margin-left: 25px;
      max-width: auto;
      min-width: auto;
      margin-bottom: 20px;
    }
    .obrezka2{
      margin-right: 35px;
      margin-left: 35px;
      max-width: auto;
      min-width: auto;
      margin-bottom: 30px;
    }
    .order-button{
      height: 45px;
      width: 160px;
      margin-right: 0;
      margin-top: 150px;
      border-radius: 31px;
      font-size: 17px;
    }
    .obrez-but{
      margin-top: 20px;
      margin-right: 1svw;
      margin-left: 1svw;
    }
    .slides-container {
      height: 340px;
    }
    .dot {
      border: 4px solid #474747;
      width: 7px;
      height: 7px;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
    }
    .navigation {
      margin-top: -20px;
    }
    .img-ad{
      margin-right: 0px;
    }
} 