.rew-hzchtoyablattakzaebalsyanahuy{
    margin-bottom: 150px;
    margin-top: 100px;
}
.rew-hzchtoyablattakzaebalsyanahuy h1{
    font-family: 'Montserrat';
    font-size: 40px;
    text-align: center;
    margin-bottom: 75px;
}
.rew-kogdajeetozaconchitsyablat{
    width: 560px;
    margin-left: auto;
    margin-right: auto;
    height: 800px;
    overflow: hidden;
    position: relative;
}
.rew-kogdajeetozaconchitsyablat iframe{
    width: 100%;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-sizing: border-box;
}
.rew-kogdajeetozaconchitsyablat a{
    box-sizing: border-box;
    text-decoration: none;
    color: #b3b3b3;
    font-size: 10px;
    font-family: 'YS Text,sans-serif';
    padding: 0 20px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
    left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-height: 14px;
    white-space: nowrap;
    padding: 0 16px;
    box-sizing: border-box;
}
@media (max-width:1500px) {
    .rew-hzchtoyablattakzaebalsyanahuy h1{
        font-family: 'Montserrat';
        font-size: 35px;
        text-align: center;
        margin-bottom: 75px;
    }
}
@media (max-width:1024px) {
    .rew-hzchtoyablattakzaebalsyanahuy{
        margin-bottom: 75px;
        margin-top: 50px;
    }
    .rew-hzchtoyablattakzaebalsyanahuy h1{
        font-family: 'Montserrat';
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
    }
    .rew-kogdajeetozaconchitsyablat{
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        height: 600px;
        overflow: hidden;
        position: relative;
    }
}
@media (max-width:768px) {
    .rew-hzchtoyablattakzaebalsyanahuy{
        margin-bottom: 75px;
        margin-top: 50px;
    }
    .rew-hzchtoyablattakzaebalsyanahuy h1{
        font-family: 'Montserrat';
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
    }
    .rew-kogdajeetozaconchitsyablat{
        width: 64svw;
        margin-left: auto;
        margin-right: auto;
        height: 96svw;
        overflow: hidden;
        position: relative;
    }
}
@media (max-width:500px) {
    .rew-hzchtoyablattakzaebalsyanahuy{
        margin-bottom: 75px;
        margin-top: 50px;
    }
    .rew-hzchtoyablattakzaebalsyanahuy h1{
        font-family: 'Montserrat';
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
    }
    .rew-kogdajeetozaconchitsyablat{
        width: 80svw;
        margin-left: auto;
        margin-right: auto;
        height: 120svw;
        overflow: hidden;
        position: relative;
    }
}
@media (max-width:400px) {
    .rew-hzchtoyablattakzaebalsyanahuy{
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .rew-hzchtoyablattakzaebalsyanahuy h1{
        font-family: 'Montserrat';
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
    }
    .rew-kogdajeetozaconchitsyablat{
        width: 90svw;
        margin-left: auto;
        margin-right: auto;
        height: 135svw;
        overflow: hidden;
        position: relative;
    }
}