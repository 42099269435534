.gc-body{
    width: 100%;
    height: 1200px;
    background-color: rgb(255, 255, 255);
}
.gc-body h1 {
    font-family: 'Montserrat';
    text-align: center;
    margin: 0;
    padding-top: 70px;
    padding-bottom: 50px;
    font-size: 40px;
}
.gc-text{ 
    margin-top: 30px; 
    width: 1000px;
    margin-left: auto; 
    margin-right: auto; 
 
} 
.gc-text p{ 
    margin-top: 0; 
    font-size: 23px; 
    font-family: 'Montserrat';
    padding-bottom: 15px;
    padding-top: 15px;
} 
.gc-images{
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    border-radius: 20px;
    overflow: hidden;
}
.gc-item1{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
}
.gc-item2{
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
}.gc-item3{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
}.gc-item4{
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
}
.gc-img{
    height: 100%;
    width: 100%;
}
.gc-info{
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;

}
.gc-info a{
    font-family: 'Montserrat';
    color: #a5a5a5;
    font-size: 21px;
}

@media (max-width:1250px) {
    .gc-body{
        height: 840px;
    }
    .gc-text{ 
        margin-top: 30px; 
        width: 650px;
        margin-left: auto; 
        margin-right: auto; 
     
    } 
    .gc-text p{ 
        margin-top: 0; 
        font-size: 19px; 
        padding-bottom: 15px;
        padding-top: 15px;
    } 
    .gc-images{
        width: 650px;
        gap:8px
    }
    .gc-info{
        width: 650px;
        margin-top: 15px;
    
    }
    .gc-info a{
        font-size: 19px;
    }
    .gc-body h1 {
        padding-top: 70px;
        padding-bottom: 20px;
        font-size: 35px;
    }
}
@media (max-width:764px) {
    .gc-body{
        height: 650px;
    }
    .gc-text{ 
        margin-top: 30px; 
        width: 400px;
        margin-left: auto; 
        margin-right: auto; 
     
    } 
    .gc-text p{ 
        margin-top: 0; 
        font-size: 17px; 
        padding-bottom: 15px;
        padding-top: 15px;
    } 
    .gc-images{
        width: 400px;
        gap:8px
    }
    .gc-info{
        width: 400px;
        margin-top: 15px;
    
    }
    .gc-info a{
        font-size: 17px;
    }
    .gc-body h1 {
        padding-top: 60px;
        padding-bottom: 0px;
        font-size: 28px;
    }
}
@media (max-width:450px) {
    .gc-body{
        height: 520px;
    }
    .gc-text{ 
        margin-top: 15px; 
        width: 280px;
        margin-left: auto; 
        margin-right: auto; 
     
    } 
    .gc-text p{ 
        margin-top: 0; 
        font-size: 16px; 
        padding-bottom: 15px;
        padding-top: 15px;
    } 
    .gc-images{
        width: 280px;
        gap:8px
    }
    .gc-info{
        width: 280px;
        margin-top: 15px;
    
    }
    .gc-info a{
        font-size: 16px;
    }
    .gc-body h1 {
        padding-top: 60px;
        padding-bottom: 0px;
        font-size: 28px;
    }
}