
/* .scmb-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.scmb-slides-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 330px;
}

.scmb-slide {
  min-width: 80%;
  max-width: 80%;
  padding: 10%;

  display: flex;
  justify-content: center; 
  align-items: center; 
}
.scmb-slide {
  min-width: 80%;
  max-width: 80%;
  padding: 10%;
  display: flex;
  justify-content: center; 
  align-items: center; 
}


.scmb-content {
  display: flex;

  width: auto;
  justify-content: center;
  align-content: center ;
  font-family: 'Montserrat-Light';
  height: 260px;
  max-width: 650px;
  min-width: 650px;
}*/

/*
.scmb-item-active{

  height: 100%;
  background-color: #0f18b5;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  min-width: 191px;
  max-width: 191px;
  border-radius: 25px;
  gap: 16px;
}
.scmb-void{
  min-width: 191px;
  max-width: 191px;
}

.scmb-navigation {
  position: relative;
  bottom: 10px;
  width: 100%;
  height: 60px;
}

.scmb-dots {
  display: flex;
  gap: 20px;
  justify-content: center; 
  position: relative;
  top: 20px;
}

.scmb-dot {
  border: 4px solid #474747;

  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid #474747;
  width: 7px;
  height: 7px;
}

.scmb-dot.active {
  background-color: #474747;
} */
.scmb-item{

  height: 310px;
  background-color: #474747;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-self: center;
  align-items: center;
  min-width: 220px;
  max-width: 220px;
  border-radius: 25px;
  gap: 16px;
  margin-left: auto;
  margin-right: auto;
}
.scmb-item img{
  width: 100%;
}

.scmb-item a{
  color: white;
  font-size: 21px;
  font-family: 'Montserrat';
}

.scmb-item button{
  background-color: #00000000;
  border:1px solid white;
  color: white;
  border-radius: 29px;
  font-family: 'Montserrat-Light';
  cursor: pointer;
  transition: all 0.9s ease;
  width: 160px;
  height: 44px;
  font-size: 16px; 
}
.scmb-item button:hover{
  color: black;
  background-color: white;
}


.sc-nextslide,
.sc-prevslide {
  transform: translateY(-50%);
  width: 70px; /* Настройте ширину и высоту по вашему усмотрению */
  height: 80px;
  background-color: rgba(230, 0, 0, 0); /* Прозрачный черный цвет фона */
  color: #fff; /* Цвет текста */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Установите z-index, чтобы элементы навигации были поверх слайдов */
  position: absolute;
  top: 43%;
  margin-left: 50px;
  margin-right: 50px;
  border: 0;
}


.sc-nextslide {
  right: 10px; /* Поместите элемент вправо */
}
.sc-nextslide img{
  rotate: -90deg;
}
.sc-prevslide {
  left: 10px; /* Поместите элемент влево */
}
.sc-prevslide img{
  rotate: 90deg;
}
@media (max-width:600px) {
  .sc-nextslide,
.sc-prevslide {
  margin-left: 30px;
  margin-right: 30px;
}
}
@media (max-width:440px) {
  .sc-nextslide,
.sc-prevslide {
  margin-left: 0px;
  margin-right: 0px;
}
.sc-dots{
  gap:15px;
}
}
@media (max-width:390px) {
  .sc-nextslide,
  .sc-prevslide {
    margin-left: -10px;
    margin-right: -10px;
  }
  .scmb-item{

    height: 250px;
    background-color: #474747;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-self: center;
    align-items: center;
    min-width: 194px;
    max-width: 194px;
    border-radius: 25px;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .scmb-item img{
    width: 100%;
  }
  
  .scmb-item a{
    color: white;
    font-size: 19px;
    font-family: 'Montserrat';
  }
}















/* ____________________________________________________________________ */

.sc-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flexbox;
  flex-direction: column;
}

.sc-slides-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 450px;

}

.sc-slide {
  min-width: 80%;
  max-width: 80%;
  padding: 10%;

  display: flex;
  justify-content: center; /* Центрирование слайдов по горизонтали */
  align-items: center; /* Центрирование слайдов по вертикали */
}

.sc-content {
  display: flex;
  height: 400px;
  min-width: 1100px;
  max-width: 1100px;
  width: auto;
  justify-content: space-between;
  font-family: 'Montserrat-Light';
}
.sc-item{
  min-width: 285px;
  max-width: 285px;
  height: 100%;
  background-color: #474747;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 25px;
  align-items: center;
}
.sc-void{
  min-width: 285px;
  max-width: 285px;
}
.sc-item img{
  width: 100%;
}

.sc-item a{
  color: white;
  font-size: 28px;
}

.sc-item button{
  width: 220px;
  height: 55px;
  background-color: #00000000;
  border:1px solid white;
  color: white;
  border-radius: 29px;
  font-size: 20px; 
  font-family: 'Montserrat-Light';
  cursor: pointer;
  transition: all 0.9s ease;
}
.sc-item button:hover{
  color: black;
  background-color: white;
}


.sc-navigation {
  position: relative;
  bottom: 10px;
  width: 100%;
  height: 60px;
}

.sc-dots {
  display: flex;
  gap: 20px;
  justify-content: center; /* Центрирование точек */
  position: relative;
  top: 20px;
}

.sc-dot {
  border: 4px solid #474747;
  width: 10px;
  height: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
}

.sc-dot.active {
  background-color: #474747;
}

@media (max-width:1350px) {
  .sc-content {
    display: flex;
    height: 355px;
    min-width: 950px;
    max-width: 950px;
    width: auto;
    font-family: 'Montserrat-Light';
  }
  .sc-item{
    min-width: 248px;
    max-width: 248px;
    border-radius: 30px;
    gap: 25px;
  }
  .sc-void{
    min-width: 248px;
    max-width: 248px;
  }
  .sc-item a{
    font-size: 23px;
  }
  .sc-item button{
    width: 180px;
    height: 48px;
    border-radius: 29px;
    font-size: 18px; 
  }
  .sc-slides-container {

    height: 380px;
  }
  .sc-dot {
    border: 4px solid #474747;
    width: 8px;
    height: 8px;
  }
}
@media (max-width:1024px) {
  .sc-content {
    height: 280px;
    max-width: 650px;
    min-width: 650px;
  }
  .sc-item{
    min-width: 191px;
    max-width: 191px;
    border-radius: 25px;
    gap: 16px;
  }
  .sc-void{
    min-width: 191px;
    max-width: 191px;
  }
  .sc-item a{
    font-size: 20px;
  }
  .sc-item button{
    width: 160px;
    height: 44px;
    border-radius: 29px;
    font-size: 16px; 
  }
  .sc-slides-container {

    height: 330px;
  }
  .sc-dot {
    border: 4px solid #474747;
    width: 7px;
    height: 7px;
  }
}
@media (max-width:600px) {
  .sc-nextslide,
.sc-prevslide {
  margin-left: 30px;
  margin-right: 30px;
}
}
@media (max-width:440px) {
  .sc-nextslide,
.sc-prevslide {
  margin-left: 0px;
  margin-right: 0px;
}
.sc-dots{
  gap:15px;
}
}
@media (max-width:390px) {
  .sc-nextslide,
  .sc-prevslide {
    margin-left: -10px;
    margin-right: -10px;
  }
  .scmb-item{

    height: 285px;
    background-color: #474747;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-self: center;
    align-items: center;
    min-width: 194px;
    max-width: 194px;
    border-radius: 25px;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .scmb-item img{
    width: 100%;
  }
  
  .scmb-item a{
    color: white;
    font-size: 19px;
    font-family: 'Montserrat';
  }
}
