.apm-body{
    width: 100%;
    height: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.apm-body h1{
    color: black;
    font-family: 'Montserrat';
    padding: 50px;
    font-size: 40px;
}
.apm-body iframe{
    width: 600px;
    height: 800px;
    margin-bottom: 90px;
    padding: 0;
}
@media (max-width:1500px) {
    .apm-body h1{
        padding: 50px;
        font-size: 35px;
    }
}
@media (max-width:1024px) {
    .apm-body h1{
        padding: 30px;
        font-size: 30px;
    }
}
@media (max-width:768px) {
    .apm-body{
        height: 800;
    }
    
    .apm-body h1{
        padding: 28px;
        font-size: 28px;
    }
    .apm-body iframe{
        width: 80%;
        height: 600px;
        margin-bottom: 90px;
        padding: 0;
    }
}
@media (max-width:500px) {
    .apm-body h1{
        padding: 28px;
        font-size: 24px;
    }
    .apm-body iframe{
        width: 80%;
        height: 400px;
        margin-bottom: 90px;
        padding: 0;
    }
}