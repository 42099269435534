/* 
*/
.ekpmb-box{
    width: 90svw;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
}
.ekpmb-prof{
    margin-top: 50px;
    width: 100%;
    height: auto;
    overflow: hidden;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    gap: 30px;
}
.ekpmb-prof img{
    height: 250px;
    width: auto;
}
.ekpmb-prof-text{
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.ekpmb-prof-text p{
    margin: 0;
}
.ekpmb-prof-text h1{
    font-size: 30px;
    margin:0;
    margin-top: 2px;
}
.ekpmb-prof-text{
    width: 100%;
    text-align: left;
    font-size: 22px;
    margin: 0;
}
.ekpmb-bold{
    font-family: 'Montserrat-SemiBold';
    font-size: 25px;
}
.ekpmb-button{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}
.ekpmb-button button{
    width: 100%;
    height: 55px;
    font-size: 23px;
    border: 1px solid #474747;
    background-color: #ffffff00;
    border-radius: 28px;
    font-family: 'Montserrat';
    color:black;
    transition: all 0.6s ease;
}
.ekpmb-button button:hover{
    background-color: #474747;
    color:white
}
.ekpmb-about{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.ekpmb-about h1{
    text-align: center;
    font-size: 28px;
}
.ekpmb-subtitle{
    background-color: #fff;
    width: auto;
    padding-top: 0;
    margin-top: 0px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.ekpmb-subtitle h1{
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    font-family: "Montserrat";
    
    text-align: left;
}
.ekpmb-ul{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
.ekpmb-ul ul li{
    margin-top: 20px;
    font-family: 'Montserrat';
    font-size: 20px;
}
.ekpmb-ul ul li::marker{
    color: #474747;
    font-size: 13px;
}
.ekpmb-services{
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.ekpmb-services-item{
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-size: 20px;
    gap:20px
}
.ekpmb-portfolio{
    margin-top: 20px;
}
.ekpmb-portfolio h1{
    text-align: center;
    margin: 40px;
    font-size: 28px;
}
.ekpmb-works {
    margin-top: 50px;
    width: 83svw;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5.2svw;
    margin-bottom: 180px;
}
.ekpmb-row{
    display: flex;
    justify-content: space-between;
}
.ekpmb-row img{
    width: 38.9svw;
    height: auto;
} 
@media (max-width:570px) {
    .ekpmb-prof{
        gap: 20px;
    }
    .ekpmb-prof img{
        height: 220px;
    }
    .ekpmb-prof-text{
        gap: 30px;
    }
    .ekpmb-prof-text h1{
        font-size: 26px;
    }
    .ekpmb-prof-text{
        font-size: 20px;
        margin: 0;
    }
    .ekpmb-bold{
        font-size: 22px;
    }
    .ekpmb-button{
        width: 80%;
        margin-top: 20px;
    }
    .ekpmb-button button{
        height: 55px;
        font-size: 22px;
    }
    .ekpmb-about h1{
        font-size: 26px;
    }
    .ekpmb-subtitle{
        margin-bottom: 20px;
    }
    .ekpmb-subtitle h1{
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .ekpmb-ul{
        width: 100%;
        margin: 0;
    }
    .ekpmb-ul ul li{
        margin-top: 20px;
        font-size: 18px;
        padding-left: 0;

    }
    .ekpmb-services{
        width: 95%;
        gap: 30px;
    }
    .ekpmb-services-item{
        font-size: 18px;
    }
    .ekpmb-portfolio h1{
        margin: 30px;
        font-size: 26px;
    }
    .ekpmb-works {
        margin-top: 30px;
        width: 87svw;
        gap: 5.2svw;
        margin-bottom: 70px;
    }
    .ekpmb-row{
        display: flex;
        justify-content: space-between;
    }
    .ekpmb-row img{
        width: 40.9svw;
        height: auto;
    } 
}
@media (max-width:450px) {
    .ekpmb-prof{
        gap: 15px;
    }
    .ekpmb-prof img{
        height: 180px;
    }
    .ekpmb-prof-text{
        gap: 20px;
    }
    .ekpmb-prof-text h1{
        font-size: 22px;
    }
    .ekpmb-prof-text{
        font-size: 18px;
        margin: 0;
    }
    .ekpmb-bold{
        font-size: 20px;
    }
    .ekpmb-button{
        width: 100%;
        margin-top: 15px;
    }
    .ekpmb-button button{
        height: 45px;
        font-size: 20px;
    }
    .ekpmb-about{
        margin-top: 10px;
    }
    .ekpmb-about h1{
        font-size: 22px;
    }
    .ekpmb-subtitle{
        margin-bottom: 20px;
    }
    .ekpmb-subtitle h1{
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .ekpmb-ul{
        width: 100%;
        margin: 0;
        margin-left: -10px;
    }
    .ekpmb-ul ul li{
        margin-top: 20px;
        font-size: 17px;
        padding-left: 0;

    }
    .ekpmb-services{
        width: 95%;
        gap: 30px;
    }
    .ekpmb-services-item{
        font-size: 17px;
    }
    .ekpmb-portfolio h1{
        margin: 30px;
        font-size: 22px;
    }
    .ekpmb-works {
        margin-top: 30px;
        width: 87svw;
        gap: 5.2svw;
        margin-bottom: 70px;
    }
    .ekpmb-row{
        display: flex;
        justify-content: space-between;
    }
    .ekpmb-row img{
        width: 40.9svw;
        height: auto;
    } 
}
@media (max-width:400px) {
    .ekpmb-prof{
        gap: 13px;
    }
    .ekpmb-prof img{
        height: 140px;
    }
    .ekpmb-prof-text{
        gap: 18px;
    }
    .ekpmb-prof-text h1{
        font-size: 20px;
    }
    .ekpmb-prof-text{
        font-size: 16px;
        margin: 0;
    }
    .ekpmb-bold{
        font-size: 18px;
    }
    .ekpmb-button{
        width: 100%;
        margin-top: 15px;
    }
    .ekpmb-button button{
        height: 45px;
        font-size: 20px;
    }
    .ekpmb-about{
        margin-top: 10px;
    }
    .ekpmb-about h1{
        font-size: 22px;
    }
    .ekpmb-subtitle{
        margin-bottom: 20px;
    }
    .ekpmb-subtitle h1{
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .ekpmb-ul{
        width: 100%;
        margin: 0;
        margin-left: -10px;
    }
    .ekpmb-ul ul li{
        margin-top: 20px;
        font-size: 17px;
        padding-left: 0;

    }
    .ekpmb-services{
        width: 95%;
        gap: 30px;
    }
    .ekpmb-services-item{
        font-size: 17px;
    }
    .ekpmb-portfolio h1{
        margin: 30px;
        font-size: 22px;
    }
    .ekpmb-works {
        margin-top: 30px;
        width: 87svw;
        gap: 5.2svw;
        margin-bottom: 70px;
    }
    .ekpmb-row{
        display: flex;
        justify-content: space-between;
    }
    .ekpmb-row img{
        width: 40.9svw;
        height: auto;
    } 
}
/* ____________________________________________________________________________ */
.ekp-box{
    width: 1400px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';

}
.ekp-prof{
    margin-top: 50px;
    width: 100%;
    height: 400px;
    overflow: hidden;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    gap: 30px;
    background: rgb(71,71,71);
    background: linear-gradient(180deg, rgba(71,71,71,0.1) 0%, rgba(71,71,71,0) 100%);
}
.ekp-prof img{
    height: 400px;
    width: auto;
}
.ekp-kostil1{
    padding: 30px;
    width: 100%;
    display: flex;
}
.ekp-prof-text{
    width: 75%;
    text-align: left;
    font-size: 20px;
}
.ekp-prof-text h1{
    font-size: 40px;
}
.ekp-bold{
    font-family: 'Montserrat-SemiBold';
    font-size: 25px;
}
.ekp-button{
    align-self: flex-end;
    width: 20%;
}
.ekp-button button{
    width: 250px;
    height: 55px;
    font-size: 23px;
    border: 1px solid #474747;
    background-color: #ffffff00;
    border-radius: 28px;
    font-family: 'Montserrat';
    color:black;
    transition: all 0.6s ease;
}
.ekp-button button:hover{
    background-color: #474747;
    color:white
}
.ekp-about{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}
.ekp-about h1{
    text-align: center;
}
.ekp-hr{
    background-color: #fff;
    width: 70%;
    padding-top: 0;
    margin-top: 60px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.ekp-hr h1{
    font-size: 26px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    font-family: "Montserrat";
}
.ekp-hr hr{
    height: 0px;
    background-color: #000000;
    border: 1px solid black;
    width: 300px;
}
.ekp-ul{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
.ekp-ul ul li{
    margin-top: 10px;
    font-family: 'Montserrat';
    font-size: 20px;
}
.ekp-services{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.ekp-services-item{
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-size: 20px;
}
.ekp-portfolio{
    margin-top: 50px;
}
.ekp-portfolio h1{
    text-align: center;
    margin: 74px;
}
.ekp-works {
    margin-top: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin-bottom: 180px;
}
.ekp-row{
    display: flex;
    justify-content: space-between;
}
.ekp-row img{
    width: 420px;
    height: 560px;
}
@media (max-width:1500px) {
    .ekp-box{
        width: 1120px;    
    }
    .ekp-prof{
        margin-top: 50px;
        width: 100%;
        height: 320px;
        overflow: hidden;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        display: flex;
        gap: 30px;
        background: rgb(71,71,71);
        background: linear-gradient(180deg, rgba(71,71,71,0.1) 0%, rgba(71,71,71,0) 100%);
    }
    .ekp-prof img{
        height: 320px;
        width: auto;
    }
    .ekp-kostil1{
        padding: 0;
        width: 100%;
        display: flex;
    }
    .ekp-prof-text{
        width: 72%;
        text-align: left;
        font-size: 19px;
    }
    .ekp-prof-text h1{
        font-size: 35px;
    }
    .ekp-bold{
        font-family: 'Montserrat-SemiBold';
        font-size: 22px;
    }
    .ekp-button{
        align-self: flex-end;
        width: auto;
        padding-bottom: 30px;
    }
    .ekp-button button{
        width: 200px;
        height: 50px;
        font-size: 20px;
    }
    .ekp-about{
        margin-top: 50px;
    }
    .ekp-about h1{
        text-align: center;
    }
    .ekp-hr{
        width: 70%;
        padding-top: 0;
        margin-top: 60px;
        margin-bottom: 40px;
        gap: 15px;
    }
    .ekp-hr h1{
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0;
    }
    .ekp-hr hr{
        height: 0px;
        width: 300px;
    }
    .ekp-row img{
        width: 350px;
        height: 467px;
    }
}
@media (max-width:1220px) {
    .ekp-box{
        width: 974px;    
    }
    .ekp-prof{
        margin-top: 50px;
        width: 100%;
        height: 300px;
        overflow: hidden;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        display: flex;
        gap: 25px;
        background: rgb(71,71,71);
        background: linear-gradient(180deg, rgba(71,71,71,0.1) 0%, rgba(71,71,71,0) 100%);
    }
    .ekp-prof img{
        height: 300px;
        width: auto;
    }
    .ekp-kostil1{
        padding: 0;
        width: 100%;
        display: flex;
    }
    .ekp-prof-text{
        width: 72%;
        text-align: left;
        font-size: 18px;
    }
    .ekp-prof-text h1{
        font-size: 35px;
    }
    .ekp-prof-text p{
        margin-top:0px;
        margin-bottom: 13px;
    }

    .ekp-bold{
        font-family: 'Montserrat-SemiBold';
        font-size: 22px;
    }
    .ekp-button{
        align-self: flex-end;
        width: auto;
        padding-bottom: 30px;
        padding-right: 30px;
    }
    .ekp-button button{
        width: 200px;
        height: 50px;
        font-size: 20px;
    }
    .ekp-about{
        margin-top: 50px;
    }
    .ekp-about h1{
        text-align: center;
    }
    .ekp-hr{
        width: auto;
        padding-top: 0;
        margin-top: 60px;
        margin-bottom: 40px;
        gap: 15px;
    }
    .ekp-hr h1{
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0;
    }
    .ekp-hr hr{
        height: 0px;
        width: 300px;
    }
    .ekp-row img{
        width: 300px;
        height: 400px;
    }
}
@media (max-width:1024px) {
    .ekp-box{
        width: 718px;    
    }
    .ekp-prof{
        margin-top: 50px;
        width: 100%;
        height: 250px;
        overflow: hidden;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        display: flex;
        gap: 25px;
        background: rgb(71,71,71);
        background: linear-gradient(180deg, rgba(71,71,71,0.1) 0%, rgba(71,71,71,0) 100%);
    }
    .ekp-prof img{
        height: 250px;
        width: auto;
    }
    .ekp-kostil1{
        padding: 0;
        width: 100%;
        display: flex;
    }
    .ekp-prof-text{
        width: 72%;
        text-align: left;
        font-size: 18px;
    }
    .ekp-prof-text h1{
        font-size: 35px;
    }
    .ekp-prof-text p{
        margin-top:0px;
        margin-bottom: 13px;
    }

    .ekp-bold{
        font-family: 'Montserrat-SemiBold';
        font-size: 22px;
    }
    .ekp-button{
        align-self: flex-end;
        width: auto;
        padding-bottom: 30px;
        padding-right: 30px;
    }
    .ekp-button button{
        width: 200px;
        height: 50px;
        font-size: 20px;
    }
    .ekp-about{
        margin-top: 50px;
    }
    .ekp-about h1{
        text-align: center;
    }
    .ekp-hr{
        width: auto;
        padding-top: 0;
        margin-top: 60px;
        margin-bottom: 40px;
        gap: 15px;
    }
    .ekp-hr h1{
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0;
    }
    .ekp-hr hr{
        height: 0px;
        width: 300px;
    }
    .ekp-row img{
        width: 210px;
        height: 280px;
    }
}