/* 


.toggle-button {
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    margin-top: 40px;
    color: #000000;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
}
*/
.owmb-box {
    width: 100%;
    height: auto;
    font-family: 'Montserrat';
    padding-bottom: 50px;
}

.owmb-box h1 {
    font-size: 28px;
    margin: 0;
    padding-top: 50px;
    text-align: center;
    padding-bottom: 30px;
}

.owmb-content {
    width: min-content;
    max-height: 565px; 
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    transition: max-height 1s ease;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.owmb-content.show-all-rows {
    max-height: 1800px;
    transition: max-height 1s ease;
}
.owmb-row{
    display: flex;
    justify-content: space-between;
    gap:30px
}
.owmb-row img{
    width: 200px;
    height: auto;
}




/*________________________________________________________________________________ */
.ow-box {
    width: 100%;
    height: auto;
    font-family: 'Montserrat';
    padding-bottom: 50px;
}

.ow-box h1 {
    font-size: 40px;
    margin: 0;
    padding-top: 90px;
    text-align: center;
    padding-bottom: 70px;

}

.ow-content {
    width: min-content;
    max-height: 1150px; /* Установите начальную максимальную высоту */
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    transition: max-height 1s ease; /* Анимация изменения высоты */
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.ow-row{
    display: flex;
    justify-content: space-between;
    gap:80px
}
.ow-row img{
    width: 400px;
    height: auto;
}
.ow-content.show-all-rows {
    max-height: 2500px; /* Установите достаточно большую максимальную высоту для показа всех строк */
    transition: max-height 1s ease; /* Анимация изменения высоты */
}
.toggle-button {
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    margin-top: 40px;
    color: #000000;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
}
@media (max-width:1500px) {
    .ow-box h1 {
        font-size: 35px;
        padding-top: 70px;
        padding-bottom: 55px;
    }
    .ow-row img{
        width: 350px;
    }
    .ow-row{
        gap:45px
    }
    .ow-content {
        max-height: 980px; /* Установите начальную максимальную высоту */
        gap: 45px;
    }
    .ow-content.show-all-rows {
        max-height: 2010px; /* Установите достаточно большую максимальную высоту для показа всех строк */
    }
    .toggle-button {
        width: 200px;
        margin-top: 40px;
        font-size: 20px;
    }
}
@media (max-width:1240px) {
    .ow-box h1 {
        font-size: 35px;
        padding-top: 60px;
        padding-bottom: 50px;
    }
    .ow-row img{
        width: 290px;
        height: auto;
    }
    .ow-row{
        gap:35px
    }
    .ow-content {
        max-height: 810px; /* Установите начальную максимальную высоту */
        gap: 35px;
    }
    .ow-content.show-all-rows {
        max-height: 1670px; /* Установите достаточно большую максимальную высоту для показа всех строк */
    }
    .toggle-button {
        width: 200px;
        margin-top: 40px;
        font-size: 19px;
    }
}
@media (max-width:1024px) {
    .ow-box h1 {
        font-size: 30px;
        padding-top: 50px;
        padding-bottom: 38px;
    }
    .ow-row img{
        width: 210px;
        height: auto;
    }
    .ow-row{
        gap:32px
    }
    .ow-content {
        max-height: 600px; /* Установите начальную максимальную высоту */
        gap: 32px;
    }
    .ow-content.show-all-rows {
        max-height: 1270px; /* Установите достаточно большую максимальную высоту для показа всех строк */
    }
    .toggle-button {
        width: 200px;
        margin-top: 40px;
        font-size: 18px;
    }
}
@media (max-width:768px) {
    .ow-box h1 {
        font-size: 30px;
        padding-top: 50px;
        padding-bottom: 38px;
    }
    .ow-row img{
        width: 160px;
        height: auto;
    }
    .ow-row{
        gap:32px
    }
    .ow-content {
        max-height: 460px; /* Установите начальную максимальную высоту */
        gap: 32px;
    }
    .ow-content.show-all-rows {
        max-height: 960px; /* Установите достаточно большую максимальную высоту для показа всех строк */
    }
    .toggle-button {
        width: 150px;
        margin-top: 40px;
        font-size: 18px;
    }
}
@media (max-width:480px) {

    .owmb-row img{
        width: 160px;
        height: auto;
    }
    .owmb-row{
        gap:32px
    }
    .owmb-content {
        max-height: 460px; /* Установите начальную максимальную высоту */
        gap: 32px;
    }
    .owmb-content.show-all-rows {
        max-height: 1600px; /* Установите достаточно большую максимальную высоту для показа всех строк */
    }

}
@media (max-width:400px) {
    .owmb-box h1 {
        font-size: 26px;
    }    
    .owmb-row img{
        width: 130px;
        height: auto;
    }
    .owmb-row{
        gap:20px
    }
    .owmb-content {
        max-height: 380px; /* Установите начальную максимальную высоту */
        gap: 20px;
    }
    .owmb-content.show-all-rows {
        max-height: 1600px; /* Установите достаточно большую максимальную высоту для показа всех строк */
    }
    .toggle-button {
        width: 150px;
        margin-top: 20px;
        font-size: 18px;
    }
}